import React, { useEffect, } from 'react';
import { makeStyles, } from '@mui/styles';
import {
  Box, Theme, Typography, Link,
} from '@mui/material';

import {
  useNavigate, useParams,
} from 'react-router-dom';
import {
  useSelector, useDispatch,
} from 'react-redux';
import { newsActions, } from '@/reducers/news.slice';
import {
  getNews, getGlobal,
} from '@/reducers/states';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import { getLanguageNewsData, } from '@/utils/tool';
import { useTheme, } from '@mui/material/styles';
import { FormattedMessage, } from 'react-intl';
import PaginationButton from '@/components/PaginationButton';
import Loading from '@/pages/Loading/Loading';
import Tag from '@/components/Tag';
import SideBar from './SideBar';

import { getImage, } from '@/utils/tool';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '0 120px',
    marginBottom: '120px',
    display: 'flex',
    gap: '60px',
    [theme.breakpoints.down('md')]: { padding: '0 24px', },
  },
  sectionLeft: {
    width: '70%',
    [theme.breakpoints.down('md')]: { width: '100%', },
  },
  sectionRight: {
    width: '30%',
    [theme.breakpoints.down('md')]: { display: 'none', },
  },
  tagsBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '8px',
  },
  imageBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      borderRadius: '10px',
      marginBottom: '16px',
    },
    [theme.breakpoints.down('md')]: { '& img': { width: '100%', }, },
  },
  linkBox: {
    display: 'flex',
    gap: '3px',
    flexWrap: 'wrap',
  },
  goBackButtonBox: {
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '16px',
    marginTop: '60px',
    cursor: 'pointer',
  },
}));

// API回傳文字中可能包含連結 Ex: '文章內容連結： &link;["text","https://www.ithome.com.tw/news/151168"] &link;'
const format = (text: string) => {
  if (text.includes('&link')) {
    const splitArr = text.split('&link;');
    // ['文章內容連結： ', '["text","https://www.ithome.com.tw/news/151171"] ', '']
    const SPLIT_ARRAY_INDEX = 1;
    // ["text","https://www.ithome.com.tw/news/151171"]
    const LINK_INDEX = 1;
    const arr = splitArr[SPLIT_ARRAY_INDEX];
    const link = JSON.parse(arr)[LINK_INDEX];

    const linkText = text.replace(/&link;.*&link;/, '');
    return {
      link,
      text: linkText,
    };
  } else {
    return { text, };
  }
};

const ArticleDetailBlock2 = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(800));
  const { locale, } = useSelector(getGlobal);
  const { newsContent, } = useSelector(getNews);
  const {
    data, loaded, loading,
  } = newsContent;
  const basicUrl = window.Config.API_HOST;
  let ArticleData = React.useMemo(() => {
    if (loaded) {
      return getLanguageNewsData(data.translations, locale);
    }
  }, [loaded,]);

  const renderContent = (item: any) => {
    switch (item.type) {
      case 'HEADING_1':
        return (
          <Box mb={2}>
            <Typography
              variant="h2"
              fontSize="24px"
              fontWeight="700"
              color="primary"
            >
              {item.content}
            </Typography>
          </Box>
        );

      case 'HEADING_2':
        return (
          <Box mb={2}>
            <Typography
              fontSize="16px"
              fontWeight="700"
              variant="h3"
              lineHeight="1.5"
              color={theme.palette.secondary.dark}
            >
              {item.content}
            </Typography>
          </Box>
        );

      case 'TEXT':
        if (format(item.content).link) {
          return (
            <Box
              mb={4}
              display="flex"
              flexWrap="wrap"
            >
              <Typography
                fontSize="16px"
                fontWeight="400"
                color={theme.palette.secondary.dark}
                whiteSpace="pre-line"
              >
                {format(item.content).text}
              </Typography>
              <Link
                target="_blank"
                href={format(item.content).link}
              >
                {format(item.content).link}
              </Link>
            </Box>
          );
        }
        return (
          <>
            {item.content.split('\n').map((text: string, index: number) => {
              return (
                <Box mb={4} key={index}>
                  <Typography
                    fontSize="16px"
                    fontWeight="400"
                    color={theme.palette.secondary.dark}
                  >
                    {format(text).text}
                  </Typography>
                </Box>
              );
            })}
          </>
        );

      case 'IMAGE':
        // 如果是直式的圖片就限制高度，若為橫式則限制寬度
        const isLongImage = item.image.height > item.image.width;
        return (
          <Box className={classes.imageBox}>
            <img
              height={isLongImage ? '466px' : 'auto'}
              width={isLongImage ? 'auto' : '100%'}
              src={`${basicUrl}/assets/${item.image.id}?width=${isMobile ? '800' : '1200'}`}
              alt={item.image.title}
            />
          </Box>
        );

      case 'CAPTION':
        return (
          <Box
            mb={2}
            className={classes.imageBox}
          >
            <Typography
              fontSize={isMobile ? '14px' : '16px'}
              fontWeight="400"
              color={theme.palette.secondary.dark}
              sx={{ opacity: '0.45', }}
            >
              {item.content}
            </Typography>
          </Box>
        );

      case 'BLANK_LINE':
        return <br />;

      case 'LINK':
        return (
          <Box
            mb={2}
            className={classes.linkBox}
          >
            <FormattedMessage id="newspageDetail.blocktwo.link.source" />
            <Link
              target="_blank"
              href={item.content}
            >
              {item.content}
            </Link>
          </Box>
        );

      case 'YOUTUBE_VIDEO':
        return (
          <Box
            width="100%"
            height={(isMobile && '185px') || '492px'}
            borderRadius="10px"
            overflow="hidden"
          >
            <iframe
              width="100%"
              height={(isMobile && '185px') || '492px'}
              src={`https://www.youtube.com/embed/${item.content.split('be/')[1]}`}
            ></iframe>
          </Box>
        );

      default:
        return null;
    }
  };

  if (!loaded) return <Loading />;
  return (
    <Box className={classes.container}>
      <Box className={classes.sectionLeft}>
        <Box mb={2}>
          <Typography
            fontSize={isMobile ? '24px' : '40px'}
            fontWeight="700"
            color={theme.palette.secondary.dark}
            lineHeight="1.4"
            variant="h1"
          >
            {ArticleData?.title}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography
            fontSize="14px"
            fontWeight="500"
            color={theme.palette.secondary.dark}
            sx={{ opacity: '0.45', }}
          >
            {moment(`${data?.date_published}`).format('YYYY.MM.DD')} / {ArticleData?.publisher}
          </Typography>
        </Box>
        <Box
          className={classes.tagsBox}
          mb={2}
        >
          {!!data?.tags?.length &&
            data?.tags?.map((tag: any, index: any) => {
              return (
                <Tag
                  key={index}
                  color="green"
                  variant="outlined"
                >
                  {tag.news_tags_id.name}
                </Tag>
              );
            })}
        </Box>
        <Box>
          {ArticleData?.contents?.map((item: any, index: any) => {
            return <Box key={index}>{renderContent(item)}</Box>;
          })}
        </Box>
        <Box className={classes.goBackButtonBox}>
          <PaginationButton
            color="green"
            reverse="180"
            textPosition="right"
            onClick={() => navigate(-1)}
          >
            <FormattedMessage id="newspageDetail.blocktwo.goBack" />
          </PaginationButton>
        </Box>
      </Box>
      <Box className={classes.sectionRight}>
        <SideBar />
      </Box>
    </Box>
  );
};

export default React.memo(ArticleDetailBlock2);